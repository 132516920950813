<!-- 
 * @description:通用机构入驻，可以使用此页面
 * @fileName: settled.vue 
 * @author:ninghf 
 * @date: 2022-09-14 17:55:13
!-->
<template>
<div class="wdn-settled-main">
    <div class="wd-settled">
        <div class="settled-header">
            <p>入驻申请</p>
        </div>
        <el-form ref="myForm" :label-position="'left'" :rules="rules" label-width="162px" :model="formData" @submit.native.prevent>
            <el-form-item label="培训方式：" prop="trainFormList">
                <el-checkbox-group v-model="formData.trainFormList" @change="handleCheckboxChange">
                    <el-checkbox label="线上培训"></el-checkbox>
                    <el-checkbox label="线下实训"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="机构名称：" prop="name" class="hasTip">
                <el-input v-model="formData.name" size="small" placeholder="请输入机构名称"></el-input>
            </el-form-item>
            <p class="formItemTip" style="color:#999;font-size:12px;">输入的名称与提交的营业执照副本上的名称保持一致</p>

            <el-form-item label="联系人：" prop="userName">
                <el-input v-model="formData.userName" size="small" :maxlength="30" placeholder="请输入联系人姓名"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：" prop="phone" class="hasTip" style="margin-bottom:8px">
                <el-input v-model.trim="formData.phone" size="small" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <div class="authCode formItemTip">
                <el-input v-model="authCode" size="small" placeholder="请输入验证码" style="width:412px"></el-input>
                <el-button v-if="Countdown" class="getcodeBtn getcodeBtnGray" type="primary" size="small" @click="getAuthCode">{{Countdown+'s'}}</el-button>
                <el-button v-else-if="isFirstGetAuthcode" class="getcodeBtn" type="primary" size="small" @click="getAuthCode">获取验证码</el-button>
                <el-button v-else class="getcodeBtn" type="primary" size="small" @click="getAuthCode">重新获取</el-button>
            </div>

            <el-form-item label="地址：" class="needRequred" style="margin-bottom:10px">
                <el-row type="flex" justify="space-between">
                    <el-col class="selectItem">
                        <el-form-item prop="province" style="margin-bottom:0">
                            <el-select v-model="formData.province" size="small" placeholder="请选择省" @change="handleProvinceChange">
                                <el-option
                                    v-for="item in provinceList"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="selectItem">
                        <el-form-item prop="city" style="margin-bottom:0">
                            <el-select v-model="formData.city" size="small" placeholder="请选择市" @change="handleCityChange">
                                <el-option
                                    v-for="item in cityList"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="selectItem">
                        <el-form-item prop="district" style="margin-bottom:0">
                            <el-select v-model="formData.district" size="small" placeholder="请选择区">
                                <el-option
                                    v-for="item in districtList"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form-item>
            <div class="formItemTip">
                <el-input v-model="formData.addressDetail" size="small" :maxlength="50" placeholder="请输入详细街道地址"></el-input>
            </div>

            <el-form-item v-if="showOffline" label="实训场所教室数量：" prop="classroomNum" key="classroomNum">
                <el-input class="numberInput" v-model="formData.classroomNum" type="number" size="small" placeholder="请输入实训场所教室数量"></el-input>
            </el-form-item>
            <el-form-item v-if="showOffline" label="实训场所总面积：" prop="siteArea" key="siteArea">
                <el-input class="numberInput" v-model="formData.siteArea" type="number" size="small" placeholder="请输入实训场所总面积m²"></el-input>
            </el-form-item>
            <el-form-item label="统一社会信用代码：" prop="organCode" key="organCode">
                <el-input v-model="formData.organCode" size="small" placeholder="请输入社会统一信用代码"></el-input>
            </el-form-item>
            <el-form-item label="营业执照副本：" prop="businessLicenseImage">
                <el-upload
                    v-loading="licenseImageUploadLoading"
                    class="avatar-uploader"
                    :action="action"
                    :headers="headers"
                    accept="image/jpg, image/jpeg, image/png"
                    :show-file-list="false"
                    :on-success="licenseImageSuccess"
                    :before-upload="beforeLicenseImageUpload"
                    :on-error="() => {licenseImageUploadLoading = false}">
                    <img v-if="formData.businessLicenseImage" :src="formData.businessLicenseImage" class="avatar">
                    <div v-else>
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                        <p class="uploadTip">点击上传</p>
                    </div>
                    <div slot="tip" class="el-upload__tip">营业执照扫描件图片后上传，支持jpg、png格式，图片大小不超过5M。</div>
                </el-upload>
            </el-form-item>
            <el-form-item v-if="showOffline" label="实训基地照片:" prop="trainingBaseImage">
                <el-upload
                    v-loading="baseImageUploadLoading"
                    class="avatar-uploader"
                    :action="action"
                    :headers="headers"
                    accept="image/jpg, image/jpeg, image/png"
                    :show-file-list="false"
                    :on-success="baseImageSuccess"
                    :before-upload="beforeBaseImageUpload"
                    :on-error="() => {baseImageUploadLoading = false}">
                    <div v-if="formData.trainingBaseImage" class="avatar">
                        <img :src="formData.trainingBaseImage">
                    </div>
                    <div v-else>
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                        <p class="uploadTip">点击上传</p>
                    </div>
                    <div slot="tip" class="el-upload__tip">实训场地图片后上传，支持jpg、png格式图片大小不超过5M。</div>
                </el-upload>
            </el-form-item>
        </el-form>
        <div class="settled-footer">
            <el-button type="primary" size="small" class="submitBtn" @click="submit">提交申请</el-button>
        </div>
    </div>
</div>
</template>
<script>
import config from '@/config/url'
import { organCodeValidator,intReg } from './../../../utils/validator'
export default {
    name:'formDialog',
    computed:{
        cityList(){
            if(this.formData.province){
                let findItem = this.provinceList.find(item => item.code === this.formData.province)
                return findItem && findItem.children
            }else{
                return []
            }
        },
        districtList(){
            if(this.formData.city){
                let findItem = this.cityList.find(item => item.code === this.formData.city)
                return findItem && findItem.children
            }else{
                return []
            }
        }
    },
    data(){
        const accountValidator = (rule, value, callback) => {
            const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
            if ( !isPhone.test(value) ) {
                callback(new Error('请输入正确的手机号'))
            } else {
                callback()
            }
        }
        const intValidator = (rule, value, callback) => {
            if ( !intReg.test(value) ) {
                callback(new Error('请输入整数'))
            } else {
                callback()
            }
        }
        return{
            licenseImageUploadLoading:false,
            baseImageUploadLoading:false,
            showOffline:false,
            action:config.API + '/resource/v1/resource/uploadPicture',
            headers:{
                token:localStorage.getItem('token')
            },
            authCode:'',
            provinceList:[],
            province:'',
            city:'',
            district:'',
            formData:{
                name:'',
                address:'',
                phone:'',
                province:'',
                city:'',
                district:'',
                trainFormList:[],
                trainingBaseImage:'',
                businessLicenseImage:'',
                addressDetail:'',
            },
            copyFormData:{},
            rules: {
                trainFormList:{required:true,message:'请选择培训方式',trigger:'blur' },
                name:{required:true,message:'请输入机构名称',trigger:'blur' },
                userName:{required:true,message:'请输入联系人姓名',trigger:'blur' },
                province:{required:true,message:'请选择省',trigger:'change' },
                city:{required:true,message:'请选择市',trigger:'change' },
                district:{required:true,message:'请选择区',trigger:'change' },
                classroomNum:[
                    {required:true,message:'请输入实训场所教室数量',trigger:'blur' },
                    {validator:intValidator,trigger:'blur'}
                ],
                siteArea:[
                    {required:true,message:'请输入实训场所总面积m²',trigger:'blur' },
                    {validator:intValidator,trigger:'blur'}
                ],
                organCode:[
                    {required:true,message:'请输入社会统一信用代码',trigger:'blur' },
                    {validator:organCodeValidator,trigger:'blur'}
                ],
                phone: [
                    {required:true,message:'请输入手机号',trigger:'blur'},
                    {validator: accountValidator,trigger: "blur"}
                ],
                businessLicenseImage:{required:true,message:'请上传营业执照副本',trigger:'blur' },
                trainingBaseImage:{required:true,message:'请上传实训基地照片',trigger:'blur' },
            },
            isFirstGetAuthcode:true,
            Countdown:0
        }
    },
    mounted(){
        this.copyFormData = {...this.formData}
        this.getRegionList()
    },
    methods:{
        getRegionList(){
            this.$api.usercenter.getTree({type:1}).then(res => {
                if(res.success){
                    this.provinceList = Object.freeze(res.data)
                }
            })
        },
        getAuthCode(){
            this.$refs.myForm.validateField('phone',(error) => {
                if(!error){
                    const data = { account: this.formData.phone,type:2 }
                    if (this.Countdown > 0) return
                    this.Countdown = 60
                    this.setCountdown()
                    this.$api.usercenter.getAuthCodePublic({ data })
                    this.isFirstGetAuthcode = false
                }
            })
        },
        setCountdown() {
            this.CountdownInterval = setInterval(() => {
                this.Countdown--;
                if (this.Countdown <= 0) {
                    clearInterval(this.CountdownInterval);
                }
            }, 1000);
        },
        handleCheckboxChange(val){
            this.showOffline = !!val.includes('线下实训')
            if(val.length != 0){
                this.$refs.myForm.clearValidate('trainFormList')
            }
        },
        beforeLicenseImageUpload(file){
            if(!['image/jpeg','image/jpg','image/png'].includes(file.type)) {
                this.$message.error('请上传jpg、png格式的图片')
                return false
            }
            if(file.size/1024/1024 > 5) {
                this.$message.error('图片大小不能超过5M')
                return false
            }
            this.licenseImageUploadLoading = true
        },
        licenseImageSuccess(res){
            this.formData.businessLicenseImage = res.data
            this.licenseImageUploadLoading = false
            this.$refs.myForm.clearValidate('businessLicenseImage')
        },
        beforeBaseImageUpload(file){
            if(!['image/jpeg','image/jpg','image/png'].includes(file.type)) {
                this.$message.error('请上传jpg、png格式的图片')
                return false
            }
            if(file.size/1024/1024 > 5) {
                this.$message.error('图片大小不能超过5M')
                return false
            }
            this.baseImageUploadLoading = true
        },
        baseImageSuccess(res){
            this.formData.trainingBaseImage = res.data
            this.baseImageUploadLoading = false
            this.$refs.myForm.clearValidate('trainingBaseImage')
        },
        handleProvinceChange(){
            this.formData.city = ''
            this.formData.district = ''
            this.$nextTick(() => {
                this.$refs.myForm.clearValidate(['city','district'])
            })
        },
        handleCityChange(){
            this.formData.district = ''
            this.$nextTick(() => {
                this.$refs.myForm.clearValidate('district')
            })
        },
        async submit(){
            try {
                await this.$refs.myForm.validate()
            } catch (error) {
                return
            }
            let data = {...this.formData}
            if(data.trainFormList && data.trainFormList.length === 2){
                data.trainOnline = 1
                data.trainOffline = 1
            }else if(data.trainFormList.includes('线上培训')){
                data.trainOnline = 1
                data.trainOffline = 0
            }else if(data.trainFormList.includes('线下实训')){
                data.trainOnline = 0
                data.trainOffline = 1
            }
            data.regionCode = data.district
            data.authCode = this.authCode
            this.$api.usercenter.branchApply(data).then((res) => {
                if(res.success){
                    this.$alert('审核结果将以短信通知给您，请注意查收', '已提交', {
                        confirmButtonText: '确定',
                        center:true,
                        customClass:'messageBox',
                        confirmButtonClass:'dialog_confirmBtn',
                        callback: () => {
                            this.$refs.myForm.resetFields();
                            this.authCode = '';
                            this.formData.addressDetail = '';
                        }
                    });
                }
            })
        }
    }
}
</script>
<style lang="stylus" scoped>
@import '../css/settled.styl'
</style>



